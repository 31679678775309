import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocalPhone, MdLocationOn, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact"} />
		<Helmet>
			<title>
				Shine Supreme Car Wash and Detailing
			</title>
			<meta name={"description"} content={"Where Your Car Gets the Royal Treatment"} />
			<meta property={"og:title"} content={"Shine Supreme Car Wash and Detailing"} />
			<meta property={"og:description"} content={"Where Your Car Gets the Royal Treatment"} />
			<meta property={"og:image"} content={"https://fronvest.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://fronvest.live/img/download.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fronvest.live/img/download.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fronvest.live/img/download.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fronvest.live/img/download.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Contacts-2">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				lg-padding="0px 0px 0px 0px"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
				margin="0px 0px 18px 0px"
				font="normal 600 42px/1.2 --fontFamily-sans"
				color="--darkL1"
				text-align="center"
				padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 25px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Contact
			</Text>
			<Text
				margin="0px 20% 50px 20%"
				font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
				lg-margin="0px 0 50px 0px"
				text-align="center"
				sm-margin="0px 0 35px 0px"
				lg-max-width="720px"
				md-padding="0px 20px 0px 20px"
				sm-text-align="left"
				sm-padding="0px 10px 0px 10px"
			>
				Have a question or need assistance? We're here to help!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="5px 15px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				align-items="center"
				lg-align-items="flex-start"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="25px 20px 5px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocalPhone}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Phone
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							+91 70540 07713
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="15px 20px 15px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Location
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							At Ishita School, Hindu University, Behind, Ashokpuram Colony, Sear Govardhan Dafi, Varanasi, Uttar Pradesh 221010, India
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="5px 20px 25px 20px"
					border-color="rgba(74, 74, 108, 0.4)"
					border-radius="25px"
					lg-flex-direction="column"
					width="600px"
					sm-padding="15px 10px 15px 10px"
					md-width="100%"
				>
					<Icon
						category="md"
						icon={MdEmail}
						size="44px"
						color="--light"
						margin="0px 25px 0px 0px"
						lg-margin="0px 0 28px 0px"
						background="--color-primary"
						padding="10px 10px 10px 10px"
						border-radius="4px"
						sm-margin="0px 0 18px 0px"
					/>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" color="--darkL1" font="normal 500 22px/1.2 --fontFamily-sans">
							Email
						</Text>
						<Text margin="0px 0px 20px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="#3e4148">
							info@fronvest.live
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});